import React from "react";
import {
  HashRouter as Router,
  Switch,
  Route,
  Link,
  withRouter
} from "react-router-dom";
import loadable from "@loadable/component";
import BasicLayout from "./layout/BasicLayout";
import UserService from "./services/User";
import ReportLayout from "./layout/ReportLayout";
import Home from "./routes/Home";
import {ConfigProvider} from "antd";
// 由于 antd 组件的默认文案是因为，所以需要修改为中文
import zhCN from 'antd/lib/locale/zh_CN';
import moment from 'moment';
import 'moment/locale/zh-cn';
moment.locale('zh-cn');

const ChangeTracker = withRouter(({match, location, history}) => {
    console.log(location.pathname, location.state);
    if (location.pathname !== '/login'&&
        !location.pathname.startsWith('/report/')&&
        !location.pathname.startsWith('/oauth/')&&
        location.pathname !== '/register'&&
        location.pathname !== '/mobile_login'&&
        location.pathname !== '/reset'&&
        location.pathname !== '/a/input'&&
        location.pathname !== '/course_schedules_view'&&
        !location.pathname.startsWith('/s/invite') &&
        !location.pathname.startsWith('/oauth/') &&
        (location.pathname && !location.pathname.startsWith('/wechat_login_callback'))&&
        (location.pathname && !location.pathname.startsWith('/wechat_qrcode'))
    ) {
        if (UserService.isExpired()) {
            console.log("登录时间过期！");
            window.location.hash = '/login';
        }
    }
    return false;
});


const Loading=()=><div>加载中...</div>;
const NotMatch=()=><div>NotMatch</div>;      

const Login = loadable(() => import("./routes/Login"), {fallback: <Loading /> });
const MobileLogin = loadable(() => import("./routes/MobileLogin"), {fallback: <Loading /> });
const Logout = loadable(() => import("./routes/Logout"), {fallback: <Loading /> });
const OauthLogin = loadable(() => import("./routes/OauthLogin"), {fallback: <Loading /> });


const WeChatLoginCallback = loadable(() => import("./routes/WeChatLoginCallback"), {fallback: <Loading/>});
const WeChatBindCallback = loadable(() => import("./routes/WeChatBindCallback"), {fallback: <Loading/>});
const WeChatQrcode = loadable(() => import("./routes/WeChatQrcode"), {fallback: <Loading/>});



// const Home=()=><div>Home</div>
const ChangeInfo = loadable(() => import("./routes/ChangeInfo"), {fallback:<Loading />});
const ChangePhone = loadable(() => import("./routes/ChangePhone"), {fallback:<Loading />});

// 修改密码页面
const ChangePassword = loadable(() => import("./routes/login/ChangePassword"),{fallback: <Loading />});
const PDFViewerTest = loadable(() => import("./routes/PDFViewerTest.jsx"),{fallback: <Loading />});

// 管理员
const Teachers = loadable(() => import("./routes/admin/Teachers"), {fallback: <Loading/>});
const Students = loadable(() => import("./routes/admin/Students"), {fallback: <Loading/>});
const Admins = loadable(() => import("./routes/admin/Admins"), {fallback: <Loading />});
const NameList = loadable(() => import("./routes/admin/NameList"), {fallback: <Loading/>});
const NameListInfo = loadable(() => import("./routes/admin/NameListInfo"), {fallback: <Loading/>});
const Rooms = loadable(() => import("./routes/admin/Rooms"), {fallback: <Loading/>});
const Room = loadable(() => import("./routes/admin/Room"), {fallback: <Loading/>});
const RoomRustdesk = loadable(() => import("./routes/admin/RoomRustdesk"), {fallback: <Loading/>});
const ClassTimeSetting = loadable(() => import("./routes/admin/ClassTimeSetting"), {fallback: <Loading/>});
const AdminScheduleView = loadable(() => import("./routes/admin/ScheduleView"), {fallback: <Loading/>});
const RoomCheckinRecords = loadable(() => import("./routes/admin/RoomCheckinRecords"), {fallback: <Loading/>});
const RoomManage = loadable(() => import("./routes/admin/RoomManage"), {fallback: <Loading/>});
const RoomSeatUsedRecords = loadable(() => import("./routes/admin/RoomSeatUsedRecords"), {fallback: <Loading/>});

const AdminCourses = loadable(() => import("./routes/admin/Courses"),{fallback: <Loading />});
const AdminCourseCopy = loadable(() => import("./routes/admin/CourseCopy"),{fallback: <Loading />});

const UserEdit = loadable(() => import("./routes/admin/UserEdit"), {fallback: <Loading/>});
const AdminWordAddin = loadable(() => import("./routes/admin/WordAddin"), {fallback: <Loading/>});
//const AdminReportStatistics = loadable(() => import("./routes/admin/ReportStatistics"), {fallback: <Loading/>});
const AdminReportBoxStatistics = loadable(() => import("./routes/admin/ReportBoxStatistics"), {fallback: <Loading/>});
const AdminReportBoxes = loadable(() => import("./routes/admin/ReportBoxes"), {fallback: <Loading/>});
const AdminSemesters = loadable(() => import("./routes/admin/Semesters"), {fallback: <Loading/>});
const AdminOnlineCourses = loadable(() => import("./routes/admin/AdminOnlineCourses"), {fallback: <Loading/>});
const AdminOnlineCourse= loadable(() => import("./routes/admin/OnlineCourse.jsx"), {fallback: <Loading/>});
const AdminReportTags= loadable(() => import("./routes/admin/AdminReportTags.jsx"), {fallback: <Loading/>});
const AdminOnlineExpStatistics= loadable(() => import("./routes/admin/AdminOnlineExpStatistics.js"), {fallback: <Loading/>});
const AdminDoorWhiteList= loadable(() => import("./routes/admin/DoorWhiteList.jsx"), {fallback: <Loading/>});
const AdminUserDoorManage= loadable(() => import("./routes/admin/UserDoorManage.jsx"), {fallback: <Loading/>});
const AdminDoorOpenRecords= loadable(() => import("./routes/admin/DoorOpenRecords.jsx"), {fallback: <Loading/>});
const AdminRoomUser= loadable(() => import("./routes/admin/RoomUsers.jsx"), {fallback: <Loading/>});
const AdminRoomSchedule= loadable(() => import("./routes/admin/RoomSchedule.jsx"), {fallback: <Loading/>});


// 软件上传
const Software = loadable(() => import("./routes/admin/Software"),{fallback: <Loading />});
// 绑定列表
const BindList = loadable(() => import("./routes/admin/BindList"),{fallback: <Loading />});
const EmoocRecords = loadable(() => import("./routes/admin/EmoocRecords.jsx"),{fallback: <Loading />});
const EmoocDeviceRecords = loadable(() => import("./routes/admin/EmoocDeviceRecords.jsx"),{fallback: <Loading />});



//操作日志列表
const Logs = loadable(() => import("./routes/admin/Logs"),{fallback: <Loading />});
const Settings = loadable(() => import("./routes/admin/Settings"),{fallback: <Loading />});


// 昆明可视化
const SandBox = loadable(() => import("./routes/visualization/SandBox"),{fallback: <Loading/>});
//昆明可视化配置
const AdminViewSetting = loadable(() => import("./routes/admin/ViewSetting"),{fallback: <Loading />});

//督导
const ReaderAdminCourses = loadable(() => import("./routes/reader_admin/Courses"),{fallback: <Loading />});
const ReaderAdminReportBoxes = loadable(() => import("./routes/reader_admin/ReportBoxes"),{fallback: <Loading />});
const ReaderAdminScheduleRecords = loadable(() => import("./routes/reader_admin/ScheduleRecords"),{fallback: <Loading />});


// 教师
const ReportBoxes = loadable(() => import("./routes/teacher/ReportBoxes"), {fallback: <Loading/>});
const ReportBox = loadable(() => import("./routes/teacher/ReportBox"), {fallback: <Loading/>});
const ReportBoxStep = loadable(() => import("./routes/teacher/ReportBoxStep"), {fallback: <Loading/>});
const ReportScoreItemEdit = loadable(() => import("./routes/teacher/ReportScoreItemEdit.jsx"), {fallback: <Loading/>});
const ReportBoxCreate = loadable(() => import("./routes/teacher/ReportBoxCreate"), {fallback: <Loading/>});
const ReportsCheck = loadable(() => import("./routes/teacher/ReportsCheck"), {fallback: <Loading/>});
const ReportsCheckResult = loadable(() => import("./routes/teacher/ReportsCheckResult"), {fallback: <Loading/>});


const ReportBoxSetting = loadable(() => import( "./routes/teacher/ReportBoxSetting"), {fallback: <Loading/>});
const Reports = loadable(() => import( "./routes/teacher/Reports"), {fallback: <Loading/>});
const ReportCommentPdf = loadable(() => import( "./routes/teacher/ReportCommentPdf"), {fallback: <Loading/>});
const ReportCommentImage = loadable(() => import( "./routes/teacher/ReportCommentImage"), {fallback: <Loading/>});
const ReportCommentFile = loadable(() => import( "./routes/teacher/ReportCommentFile"), {fallback: <Loading/>});
const ReportCommentRawFiles = loadable(() => import( "./routes/teacher/ReportCommentRawFiles"), {fallback: <Loading/>});
const ReportCommentTask = loadable(() => import( "./routes/teacher/ReportCommentTask.jsx"), {fallback: <Loading/>});
const ReportBoxCopy = loadable(() => import("./routes/teacher/ReportBoxCopy.jsx"),{fallback:<Loading />});

const CommentLibrary = loadable(() => import( "./routes/teacher/CommentLibrary"), {fallback: <Loading/>});
const ResourceManage = loadable(() => import("./routes/student/ResourceManage"), {fallback: <Loading/>});
const ReportStatistics = loadable(() => import("./routes/teacher/ReportStatistics"),{fallback: <Loading />});
const Courses = loadable(() => import("./routes/teacher/Courses"),{fallback: <Loading />});
const Course = loadable(() => import("./routes/teacher/Course"),{fallback: <Loading />});
const CourseSchedules = loadable(() => import("./routes/teacher/CourseSchedules"), {fallback: <Loading/>});
const CourseSchedulesView = loadable(() => import("./routes/CourseSchedulesView"), {fallback: <Loading/>});

const ScheduleRecords = loadable(() => import("./routes/teacher/ScheduleRecords"),{fallback:<Loading />});
const ScheduleRecordStudents = loadable(() => import("./routes/teacher/ScheduleRecordStudents"),{fallback:<Loading />});
const ScheduleView = loadable(() => import("./routes/teacher/ScheduleView"), {fallback: <Loading/>});

const PasswordReset = loadable(() => import("./routes/teacher/PasswordReset"), {fallback: <Loading/>});
const TeacherReportBoxStatistics = loadable(() => import("./routes/teacher/ReportBoxStatistics"), {fallback: <Loading/>});

const Exams = loadable(() => import("./routes/teacher/Exams"), {fallback: <Loading/>});
const ExamInfo = loadable(() => import("./routes/teacher/ExamInfo"), {fallback: <Loading/>});
const ExamCreate = loadable(() => import("./routes/teacher/ExamCreate"), {fallback: <Loading/>});
const TitleLibs = loadable(() => import( "./routes/teacher/TitleLibs"), {fallback: <Loading/>});
const TitleLib = loadable(() => import( "./routes/teacher/TitleLib"), {fallback: <Loading/>});
const TitleLibCreate = loadable(() => import("./routes/teacher/TitleLibCreate"), {fallback: <Loading/>});
const TitleCreate = loadable(() => import("./routes/teacher/TitleCreate"), {fallback: <Loading/>});
const TitleChange = loadable(() => import("./routes/teacher/TitleChange"), {fallback: <Loading/>});

const CapabilityMapDetail = loadable(() => import("./routes/teacher/CapabilityMapDetail"), {fallback: <Loading/>});
const BoxCapabilityMapDetail = loadable(() => import("./routes/teacher/BoxCapabilityMapDetail"), {fallback: <Loading/>});
const StudentExamUngraded = loadable(() => import( "./routes/teacher/StudentExamUngraded"), {fallback: <Loading/>});

const SmartClassSchedule = loadable(() => import( "./routes/teacher/SmartClassSchedule"), {fallback: <Loading/>});
const SmartClassScheduleDetail = loadable(() => import( "./routes/teacher/SmartClassScheduleDetail"), {fallback: <Loading/>});
const TableTest = loadable(() => import( "./routes/teacher/TableTest"), {fallback: <Loading/>});
//学生
const StudentReportBoxes = loadable(() => import("./routes/student/ReportBoxes"), {fallback: <Loading/>});
const StudentReportBox = loadable(() => import("./routes/student/ReportBox"), {fallback: <Loading/>});
const StudentReportBoxStep = loadable(() => import("./routes/student/ReportBoxStep"), {fallback: <Loading/>});
const StudentScoreItemTaskEdit = loadable(() => import("./routes/student/ScoreItemTaskEdit"), {fallback: <Loading/>});
const StudentScoreItemTaskView = loadable(() => import("./routes/student/ScoreItemTaskView.jsx"), {fallback: <Loading/>});


const ReportCommentViewPdf = loadable(() => import("./routes/student/ReportCommentViewPdf"), {fallback: <Loading/>});
const ReportCommentViewImage = loadable(() => import("./routes/student/ReportCommentViewImage"), {fallback: <Loading/>});
const ReportEdit = loadable(() => import("./routes/student/ReportEdit"), {fallback: <Loading/>});
const ReportView = loadable(() => import( "./routes/student/ReportView"), {fallback: <Loading/>});
const StudentCourses = loadable(() => import( "./routes/student/Courses"), {fallback: <Loading/>});
const StudentCourse = loadable(() => import( "./routes/student/Course"), {fallback: <Loading/>});
const InviteAddBox = loadable(() => import("./routes/student/InviteAddBox"),{fallback:<Loading />});
const SupportDownload = loadable(() => import("./routes/student/SupportDownload"),{fallback:<Loading />});
const WordAddin = loadable(() => import("./routes/student/WordAddin"),{fallback:<Loading />});
const XuanKe=loadable(() => import("./routes/student/XuanKe"),{fallback:<Loading />});
const StudentScheduleView = loadable(() => import( "./routes/student/ScheduleView"), {fallback: <Loading/>});
const StudentRooms = loadable(() => import( "./routes/student/StudentRooms"), {fallback: <Loading/>});
const StudentRustdesk = loadable(() => import( "./routes/student/StudentRustdesk"), {fallback: <Loading/>});
const StudentOnlineCourses = loadable(() => import( "./routes/student/OnlineCourses"), {fallback: <Loading/>});
const StudentOnlineCourse = loadable(() => import( "./routes/student/OnlineCourse"), {fallback: <Loading/>});
const StudentOnlineExp = loadable(() => import( "./routes/student/OnlineExp"), {fallback: <Loading/>});
const StudentExams = loadable(() => import( "./routes/student/StudentExams"), {fallback: <Loading/>});
const StudentExamInfo = loadable(() => import( "./routes/student/StudentExamInfo"), {fallback: <Loading/>});
const StudentExamScore = loadable(() => import( "./routes/student/StudentExamScore"), {fallback: <Loading/>});



// 物料
const AutoBorrowKinds = loadable(() => import("./routes/auto_borrow/Kinds"));
const AutoBorrowCategorys = loadable(() => import("./routes/auto_borrow/Categorys"));

const AutoBorrowImportGoods = loadable(() => import("./routes/auto_borrow/ImportGoods"));
const AutoBorrowImportKinds = loadable(() => import("./routes/auto_borrow/ImportKinds"));
const AutoBorrowBorrowGoods = loadable(() => import("./routes/auto_borrow/BorrowGoods"));
const AutoBorrowBorrowList = loadable(() => import("./routes/auto_borrow/BorrowList"));
const AutoBorrowKindGoods = loadable(() => import("./routes/auto_borrow/KindGoods"));
const AutoBorrowReturnToWarehouse = loadable(() => import("./routes/auto_borrow/ReturnToWarehouse"));
const AutoBorrowQueryRecords = loadable(() => import("./routes/auto_borrow/QueryRecords"));
const AutoBorrowFaultRecords = loadable(() => import("./routes/auto_borrow/FaultRecords"));
const AutoBorrowExpBoxRecords = loadable(() => import("./routes/auto_borrow/ExpBoxRecords"));
const AutoBorrowBatchBorrow = loadable(() => import("./routes/auto_borrow/BatchBorrow"));
const AutoBorrowBatchBorrowInfo = loadable(() => import("./routes/auto_borrow/BatchBorrowInfo"));
const AutoBorrowCabinetManage = loadable(() => import("./routes/auto_borrow/CabinetManage"));
const AutoBorrowCabinetPreview = loadable(() => import("./routes/auto_borrow/CabinetPreview"));

const WhiteList= loadable(() => import("./routes/auto_borrow/WhiteList"));
const WhiteListInfo= loadable(() => import("./routes/auto_borrow/WhiteListInfo"));
const AutoBorrowBorrowTime = loadable(() => import("./routes/auto_borrow/BorrowTime"));
const AutoBorrowGetGoods = loadable(() => import("./routes/auto_borrow/GetGoods"));

const TeacherReportCommentPdfWithLayout=(props)=>(
    <ReportLayout><ReportCommentPdf {...props}/></ReportLayout>);
const TeacherReportCommentImageWithLayout=(props)=>(
    <ReportLayout><ReportCommentImage {...props}/></ReportLayout>);
const TeacherReportRawFilesWithLayout=(props)=>(
    <ReportLayout><ReportCommentRawFiles {...props}/></ReportLayout>);
const TeacherReportCommentFileWithLayout=(props)=>(
    <ReportLayout><ReportCommentFile {...props}/></ReportLayout>);
const TeacherReportCommentTaskWithLayout=(props)=>(
    <ReportLayout><ReportCommentTask {...props}/></ReportLayout>);

export default  ()=>{
  return (
  <ConfigProvider locale={zhCN}>
    <Router>
        <ChangeTracker/>
        <Switch>
            <Route exact path="/login" component={Login}/>
            <Route exact path="/mobile_login" component={MobileLogin}/>
            <Route exact path="/course_schedules_view" component={CourseSchedulesView}/>
            <Route exact path="/logout" component={Logout}/>
            <Route  path="/oauth/:token" component={OauthLogin}/>
            <Route path={'/pdf_view_test'} component={PDFViewerTest} />

            <Route exact path="/wechat_login_callback/:code" component={WeChatLoginCallback}/>
            <Route exact path="/wechat_bind_callback/:code" component={WeChatBindCallback}/>
            {/*可视化*/}
            <Route path={'/visualization'} component={SandBox} />
            <Route path="/report/:id" component={ReportView}/>
            <Route path="/wechat_qrcode/:deviceId" component={WeChatQrcode}/>
            <Route path="/t/report/comment_pdf/:reportId/:boxId" component={TeacherReportCommentPdfWithLayout}/>
            <Route path="/t/report/comment_image/:reportId/:boxId" component={TeacherReportCommentImageWithLayout}/>
            <Route path="/t/report/comment_raw_files/:reportId/:boxId" component={TeacherReportRawFilesWithLayout}/>
            <Route path="/t/report/comment_file/:reportId/:boxId" component={TeacherReportCommentFileWithLayout}/>
            <Route path="/t/report/task_comment/:reportId/:boxId" component={TeacherReportCommentTaskWithLayout}/>
            <Route path={'/s/invite/:teacher/:boxId'} component={InviteAddBox} />
            <BasicLayout>
                {/*修改密码*/}
                <Route path={"/change_info"} component={ChangeInfo} />
                <Route path={"/change_phone"} component={ChangePhone} />
                <Route path={'/change_password'} component={ChangePassword} />
                {/*管理员*/}
                <Route exact path="/a/teachers"><Teachers /></Route>
                <Route exact path="/a/students" component={Students}/>
                <Route exact path="/a/rooms" component={Rooms}/>
                <Route exact path="/a/courses" component={AdminCourses}/>
                <Route exact path="/a/course_copy" component={AdminCourseCopy}/>
                <Route path="/a/room/:id" component={Room}/>
                <Route path="/a/room_rustdesk/:id" component={RoomRustdesk}/>
                <Route exact path={'/a/admins'} component={Admins}/>
                <Route exact path="/a/name_list" component={NameList}/>
                <Route  path="/a/name_list_info/:id" component={NameListInfo}/>
                <Route path="/a/userEdit/:username" component={UserEdit}/>
                <Route path="/a/word_addin" component={AdminWordAddin}/>
                <Route path={'/a/software'} component={Software} />
                <Route path={'/a/bind_list'} component={BindList} />
                <Route path={'/a/emooc_records'} component={EmoocRecords} />
                <Route path={'/a/emooc_device_records'} component={EmoocDeviceRecords} />

                <Route path={'/a/logs'} component={Logs} />
                <Route path={'/a/settings'} component={Settings} />
                <Route path={'/a/classtime_setting'} component={ClassTimeSetting} />
                <Route path="/a/schedule_view" component={AdminScheduleView}/>
                <Route path="/a/room_checkin_records" component={RoomCheckinRecords}/>
                <Route path="/a/room_checkin_Manage" component={RoomManage}/>
                <Route path="/a/room_seat_use_records" component={RoomSeatUsedRecords}/>
                <Route path="/a/setting" component={AdminViewSetting}/>
                <Route path="/a/report/statistics" component={AdminReportBoxStatistics} />
                <Route exact path="/a/report/boxes" component={AdminReportBoxes}/>
                <Route exact path="/a/semesters" component={AdminSemesters}/>
                <Route exact path="/a/online_courses" component={AdminOnlineCourses}/>
                <Route  path="/a/online_course/:id" component={AdminOnlineCourse}/>
                <Route exact path="/a/online_exp_statistics" component={AdminOnlineExpStatistics}/>
                <Route  path="/a/report/ReportTags" component={AdminReportTags}/>
                <Route  path="/a/door_whiteList" component={AdminDoorWhiteList}/>
                <Route  path="/a/user_door_manage" component={AdminUserDoorManage}/>
                <Route  path="/a/door_open_records" component={AdminDoorOpenRecords}/>
                <Route  path="/a/room_users/:id" component={AdminRoomUser}/>
                <Route  path="/a/room_schedule/:id" component={AdminRoomSchedule}/>


                
                
                
                {/*督导*/}
                <Route path="/ra/courses" component={ReaderAdminCourses} />
                <Route path="/ra/report_boxes" component={ReaderAdminReportBoxes} />
                <Route path="/ra/schedule_records" component={ReaderAdminScheduleRecords} />
                {/*物料*/}
                <Route path="/auto_borrow/kinds" component={AutoBorrowKinds}/>
                <Route path="/auto_borrow/categorys" component={AutoBorrowCategorys}/>
                <Route path="/auto_borrow/kindGoods/:id" component={AutoBorrowKindGoods}/>
                <Route path="/auto_borrow/import_goods" component={AutoBorrowImportGoods}/>
                <Route path="/auto_borrow/import_kinds" component={AutoBorrowImportKinds}/>
                <Route path="/auto_borrow/borrow_goods" component={AutoBorrowBorrowGoods}/>
                <Route path="/auto_borrow/borrow_list" component={AutoBorrowBorrowList}/>
                <Route path="/auto_borrow/returnToWarehouse" component={AutoBorrowReturnToWarehouse}/>
                <Route path="/auto_borrow/query_records" component={AutoBorrowQueryRecords}/>
                <Route path="/auto_borrow/fault_records" component={AutoBorrowFaultRecords}/>
                <Route path="/auto_borrow/exp_box_records" component={AutoBorrowExpBoxRecords}/>
                <Route path="/auto_borrow/batch_borrow" component={AutoBorrowBatchBorrow}/>
                <Route path="/auto_borrow/borrow_time" component={AutoBorrowBorrowTime}/>
                <Route path="/auto_borrow/get_goods" component={AutoBorrowGetGoods}/>
                <Route path="/auto_borrow/cabinet_manage" component={AutoBorrowCabinetManage}/>
                {/* <Route path="/auto_borrow/cabinet_shelves" component={AutoBorrowCabinetShelves}/> */}
                <Route path="/auto_borrow/cabinet_preview" component={AutoBorrowCabinetPreview}/>



                <Route path="/white_list" component={WhiteList}/>
                <Route path="/white_list_info/:id" component={WhiteListInfo}/>
                <Route exact path="/auto_borrow/batch_borrow_info/:id" component={AutoBorrowBatchBorrowInfo}/>


                {/*教师*/}
                <Route exact path="/t/courses" component={Courses}/>
                <Route path="/t/course/:id" component={Course}/>
                <Route path="/t/course_schedules" component={CourseSchedules}/>
                <Route path="/t/schedules_record" component={ScheduleRecords}/>
                <Route path="/t/schedule_record/:id" component={ScheduleRecordStudents}/>
                <Route path="/t/schedules_view" component={ScheduleView}/>
                <Route exact path="/t/report/boxes" component={ReportBoxes}/>
                <Route exact path="/t/report_box/copy" component={ReportBoxCopy}/>
                <Route path="/t/report/box/:id/:tabKey?" component={ReportBox}/>
                <Route path="/t/report/box_step/:id/:tabKey?" component={ReportBoxStep}/>
                <Route path="/t/report_score_item/edit/:name/:boxId" component={ReportScoreItemEdit}/>
                <Route path="/t/create/report/box" component={ReportBoxCreate}/>
                <Route path="/t/reports/check" component={ReportsCheck}/>
                <Route path="/t/reports/check_result/:id" component={ReportsCheckResult}/>
                <Route path="/t/report/box_setting/:id" component={ReportBoxSetting}/>
                {/*<Route path="/t/report/box/:id" component={Reports}/>*/}
                <Route path="/t/comment_library" component={CommentLibrary}/>
                <Route path="/t/report/statistics" component={TeacherReportBoxStatistics} />
                <Route path="/t/password_reset" component={PasswordReset}/>
                <Route path="/t/report/comment_view_pdf/:reportId/:boxId" component={ReportCommentViewPdf}/>


                <Route path="/t/exams" component={Exams}/>
                <Route path="/t/exam/info/:id/:tabKey?" component={ExamInfo}/>
                <Route path="/t/exam/create" component={ExamCreate}/>
                <Route path="/t/exam/ungraded/:id" component={StudentExamUngraded} />
                <Route path="/t/title_libs" component={TitleLibs}/>
                <Route path="/t/title_lib/info/:id/:activeKey" component={TitleLib}/>
                <Route path="/t/title_lib/create" component={TitleLibCreate}/>
                {/* <Route path="/t/title/create" component={CreateTitle}/> */}
                <Route path="/t/title/create/:id/:titleLibName" component={TitleCreate}/>
                <Route path="/t/title/change/:id" component={TitleChange}/>
                <Route path="/t/box_step/CapabilityMapDetail/:id" component={CapabilityMapDetail}/>
                <Route path="/t/box_step/BoxCapabilityMapDetail/:id/:userName" component={BoxCapabilityMapDetail}/>
                <Route path="/t/SmartClassSchedule" component={SmartClassSchedule}/>
                <Route path="/t/SmartClassScheduleDetail/:id" component={SmartClassScheduleDetail}/>
                
                <Route path="/t/SmartClassScheduleDetail/:id" component={SmartClassScheduleDetail}/>
                <Route path="/t/tableTest" component={TableTest}/>
                {/*学生*/}
                <Route exact path="/s/report/boxes" component={StudentReportBoxes}/>
                <Route exact path="/s/report/box/:id" component={StudentReportBox}/>
                <Route exact path="/s/report/box_step/:id" component={StudentReportBoxStep}/>
                <Route exact path="/s/score_item_task/edit/:boxId/:score_item" component={StudentScoreItemTaskEdit}/>
                <Route exact path="/s/score_item_task/view/:id" component={StudentScoreItemTaskView}/>
                <Route exact path="/s/courses" component={StudentCourses}/>
                <Route  path="/s/course/:id" component={StudentCourse}/>
                <Route  path="/s/report/comment_view_pdf/:reportId/:boxId" component={ReportCommentViewPdf}/>
                <Route  path="/s/report/comment_view_image/:reportId/:boxId" component={ReportCommentViewImage}/>
                <Route  path="/s/report/edit/:reportId/:boxId" component={ReportEdit}/>
                <Route  path="/s/resource/manage" component={ResourceManage}/>
                <Route  path="/s/support_download" component={SupportDownload}/>
                <Route  path="/s/wordaddin" component={WordAddin}/>
                <Route path="/s/xk" component={XuanKe}/>
                <Route path="/s/schedule_view" component={StudentScheduleView}/>
                <Route path="/s/virtual_rooms" component={StudentRooms}/>
                <Route path="/s/virtual_room/:id" component={StudentRustdesk}/>
                <Route path="/s/online_courses" component={StudentOnlineCourses} />
                <Route path="/s/online_course/:id" component={StudentOnlineCourse} />
                <Route path="/s/online_exp/:id/:courseId" component={StudentOnlineExp} />
                <Route path="/s/studentExams" component={StudentExams} />
                <Route path="/s/student_Exam/info/:id" component={StudentExamInfo} />
                <Route path="/s/student_Exam/score/:id" component={StudentExamScore} />
                
                <Route exact path="/"><Home /></Route>
            </BasicLayout>
            <Route path="/*" component={NotMatch}/>
          </Switch>
    </Router>
  </ConfigProvider>
  );
}