let menuData = [
    {
        "title": "课程",
        "url": "/t/courses"
    },
    {
        "title": "作业",
        "key":"reportbox_manage",
        "children": [
            {
                "title": "作业箱",
                "url": "/t/report/boxes"
            },{
                "title": "评语库",
                "url": "/t/comment_library"
            },
            {
                "title": "数据采集",
                "url": "/s/resource/manage"
            },
            // {
            //     "title": "文件查重",
            //     //上传文件查重
            //     "url": "/t/reports/check"
            // },
           ]
    },
    {
        "title": "教学安排",
        "children": [
            {
                "title": "排课计划",
                "url": "/t/course_schedules"
            },
            {
                "title": "排课记录",
                "url": "/t/schedules_record"
            },
            {
                "title": "课表",
                "url": "/t/schedules_view"
            },
            // {
            //     "title": "智能排课",
            //     "url": "/t/SmartClassSchedule"
            // },
            // {
            //     "title": "表格测试",
            //     "url": "/t/TableTest"
            // },
        ]
    },
    {
        "title":"统计报表",
        "url":"/t/statistics",
        "children": [
            {
                "title": "作业统计",
                "url": "/t/report/statistics"
            },
            {
                "title": "实验室签到记录",
                "url": "/a/room_checkin_records",
            },
            {
                "title": "实验箱使用数据",
                "url": "/a/emooc_records"
            },
            {
                "title": "实验过程数据",
                "url": "/a/emooc_device_records"
            },
        ]

    },
    {
        "title": "小测验管理",
        "key":"exams_manage",
        "children": [
            {
                "title": "小测验",
                "url": "/t/exams"
            },{
                "title": "题库",
                "url": "/t/title_libs"
            },]
    },
    {
        "title": "用户管理",
        "key":"user_manage",
        "children": [
            {
                "title": "名单管理",
                "url": "/a/name_list"
            },
            {
                "title": "导入学生",
                "url": "/a/students",
            },
            {
                "title": "重置密码",
                "url": "/t/password_reset"
            },]
    },
    
    {
        "title": "其他",
        "children": [
            {
                "title": "软件下载",
                "url": "/s/support_download"
            },
            {
                "title": "实验室管理",
                "url": "/a/room_checkin_manage",
            },
            {
                "title": "实验室使用记录管理",
                "url": "/a/room_seat_use_records",
            },
        ]
    },


    // {
    //     "title":"意见建议",
    //     "url":"https://support.qq.com/product/378305"
    // }

];
if(!window.config.is_open_paike){
    menuData=menuData.filter(menu=>menu.title!=='排课管理')
}
if(!window.config.is_open_test){
    menuData=menuData.filter(menu=>menu.key!=='exams_manage')
}

module.exports = menuData;
